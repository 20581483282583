import React, {useState} from 'react';
import {Button, Dropdown, Radio} from "semantic-ui-react";
import Upload from "../Upload";

interface Status {
    name: string;
    status: boolean;
    photo_url?: string;
}

const StoreStatus: React.FC<{ loading?: boolean; onSave?(data: Status): void; }> = ({loading, onSave}) => {
    const [state, setState] = useState<Status>({name: "", status: false, photo_url: ""});
    const options = (window as any)._INIT_DATA_?.storeStatus ?? [];
    const disabled = state.name !== '' ? (state.status ? false : state.photo_url === '') : true;
    return (
        <div className="store-status">
            <h5 className={"text-uppercase mb-3"}>Tình trạng cửa hàng</h5>
            {
                options?.map(({status, name}: any, index: number) => {
                    return (
                        <div className={"w-100 border pt-2 px-2 mb-2"} key={index}>
                            <Radio label={name} checked={state.name === name}
                                   onChange={() => {
                                       setState(prev => ({...prev, ...{name, status}}));
                                   }}/>
                        </div>
                    )
                })
            }
            {
                !state.status && (
                    <Upload
                        onComplete={({photo_url}) => setState(prev => ({...prev, photo_url}))}
                    />
                )
            }
            <Button
                loading={loading}
                className={"w-100 mt-3"}
                disabled={disabled}
                onClick={() => {
                    onSave && onSave(state);
                }}>
                {state.status ? 'Tiến hành chấm công' : 'Lưu tình trạng cửa hàng'}
            </Button>
        </div>
    );
}

export default StoreStatus;
