import React, {useCallback, useContext, useEffect, useState} from "react";
import {Attendance, Scheduled, ScheduleTime} from "../../models/Attendance";
import {Button} from "semantic-ui-react";
import moment from "moment";
import {Redirect} from "react-router-dom";
import {useRequest} from "../../hooks/useRequest";
import urls from "../../settings/urls";
import {useGeolocation} from "react-use";
import Upload from "../../components/Upload";
import useTimeOut from "../../hooks/useTimeOut";
import ChooseShift from "./ChooseShift";
import Error from "../../components/Error";
import {Message} from 'semantic-ui-react'
import {useCaptureCamera, useGPS} from "../../settings/constants";
import {AttendanceContext} from "../../contexts/AttendanceContext";
import StoreStatus from "../../components/StoreStatus";

interface StaffAttendanceProps {
    scheduled: Scheduled;
    attendance: Attendance | null;
    date: string;
}

export interface IState {
    time: ScheduleTime | null;
    type: string;
    useCapture: boolean;
    store_is_opening?: boolean;
    store_opening_status?: string;
}

const FormAttendance: React.FC<StaffAttendanceProps> = ({date, attendance, scheduled}) => {
    const {schedule, id, store} = scheduled;
    const location = useGeolocation();
    const [state, setState] = useState<IState>({
        time: null,
        type: "check-out",
        useCapture: false,
        store_is_opening: false,
        store_opening_status: ''
    });
    const [photoURL, setPhotoURL] = useState();
    const [isUploading, setUpload] = useState(false);
    const [isChangedPhotoURL, setPhotoURLChanged] = useState(false);
    const [request, onSubmit] = useRequest<any>(urls.saveAttendance);
    const [isSubmit, setSubmit] = useState(false);
    const {onUpdate} = useContext(AttendanceContext);

    const handleSave = useCallback((args: any = {...state, photo_url: photoURL}) => {
        const {accuracy, latitude, longitude} = location;

        const data: any = {
            staff_schedule_id: id,
            date,
            time: moment().format("LTS"),
            geo_coords: `${latitude},${longitude}`,
            geo_accuracy: accuracy,
            schedule_time_id: args.time?.id,
            type: args.type,
            photo_url: args.photo_url,
            store_is_opening: args.store_is_opening,
            store_opening_status: args.store_opening_status,
        };
        setSubmit(false);
        onSubmit(data);
        onUpdate({staff_schedule_id: id});
    }, [photoURL, onUpdate, setSubmit, location, date, onSubmit, id,]);

    const handleSubmit = useCallback(() => {
        setSubmit(true);
        if (isChangedPhotoURL) {
            setUpload(true);
        } else {
            setUpload(false);
            handleSave();
        }
    }, [setUpload, handleSave, setSubmit, isChangedPhotoURL]);

    useEffect(() => {
        if (photoURL && isSubmit) {
            handleSave();
        }
    }, [photoURL, isSubmit, handleSave]);

    const [timeOut, runTimeOut] = useTimeOut(1000);

    useEffect(() => {
        if (request.data) {
            runTimeOut();
        }
    }, [request, runTimeOut]);

    const handleChangeState = useCallback((nextState: IState) => {
        if (request.loading) {
            return;
        }
        setState(prev => ({...prev, ...nextState}));
        setPhotoURLChanged(false);
    }, [setState, setPhotoURLChanged, request.loading]);
    const {useCapture} = state;
    const isShowSubmit = !useCapture || photoURL;

    useEffect(() => {
        if (attendance?.attendance?.store_opening_status) {
            setState(prev => ({
                ...prev,
                store_is_opening: attendance.attendance.store_is_opening,
                store_opening_status: attendance.attendance.store_opening_status
            }))
        }
    }, [attendance]);

    if (timeOut) {
        return <Redirect to={'/menu'}/>;
    }

    if (useGPS && location.error) {
        return (
            <Message warning>
                <Message.Header>Không thể lấy vị trí GPS!</Message.Header>
                <p>Bạn đã tắt định vị, vui lòng mở GPS hoặc bỏ chặn định vị</p>
            </Message>
        )
    }
    // console.log(isShowSubmit, !useCapture , photoURL);

    if (!state.store_opening_status) {
        return (
            <div className="pt-3">
                <StoreStatus
                    loading={request.loading}
                    onSave={({name, status, photo_url}) => {
                        // store is opening
                        if (status) {
                            setState(prev => ({
                                ...prev,
                                store_is_opening: status,
                                store_opening_status: name,
                            }))
                        } else {
                            // store closed, disable attendance
                            handleSave({
                                ...state,
                                store_is_opening: status,
                                store_opening_status: name,
                                photo_url: photo_url,
                            });
                        }
                    }}
                />
            </div>
        )
    } else if (!state?.store_is_opening) {
        return (
            <div className="w-100 mt-3">
                <Message warning>
                    <p>Cửa hàng đã đóng cửa, vui lòng quay lại sau</p>
                    <p>Lý do: <strong>{state?.store_opening_status}</strong></p>
                    <p>Cập nhập: <strong>{moment(attendance?.attendance?.updated_at).format('L LTS')}</strong></p>
                </Message>
                <Button secondary onClick={() => {
                    setState(prev => ({
                        ...prev,
                        store_is_opening: false,
                        store_opening_status: '',
                    }))
                }}>Bạn muốn thay đổi tình trạng cửa hàng ?</Button>
            </div>
        )
    }

    return (
        <div id="staff_attendance">
            <div className="w-100">
                <ChooseShift
                    disabled={request.loading || isUploading}
                    attendance={attendance}
                    schedule={schedule}
                    value={state}
                    onChange={handleChangeState}
                />
                {state.time &&
                (
                    <div className="w-100" key={state.time?.id}>
                        {
                            state.useCapture && <Upload
                                store={store}
                                useCapture={useCaptureCamera}
                                onChange={value => {
                                    setPhotoURLChanged(!!value)
                                }
                                }
                                onComplete={({photo_url}) => setPhotoURL(photo_url)}
                                isUpload={isUploading}/>
                        }
                        {
                            isShowSubmit && (
                                <Button loading={request.loading} onClick={handleSubmit}
                                        className={"btn-block mt-2 text-uppercase font-roboto"} size="large"
                                        color={request.data ? "linkedin" : "instagram"}>
                                    {request.data ? "Chấm công hoàn tất" : "Tiến hành chấm công"}
                                </Button>
                            )
                        }
                    </div>
                )
                }
                <Error errors={request.errors}/>
            </div>
        </div>
    );
};

export default React.memo(FormAttendance);
